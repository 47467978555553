import React from "react"
// import PropTypes from 'prop-types';

import { Grid } from "@material-ui/core"
import Carousel from "react-material-ui-carousel"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { makeStyles } from "@material-ui/core/styles"

const imageItems = [
  `${process.env.AWS_S3_URL}/assets/iTopPlus1499563213115.png`,
  `${process.env.AWS_S3_URL}/assets/iTopPlus1257689393023.png`,
  `${process.env.AWS_S3_URL}/assets/iTopPlus506412461923.png`,
  `${process.env.AWS_S3_URL}/assets/iTopPlus938008459690.png`
]

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "30px",
    fontWeight: "600",
    color: "#444444",
    textTransform: "uppercase",
    // margin: "100px",
  },
  underLineTitle: {
    borderBottom: "1px solid #444",
    width: "50%",
    margin: "5px auto",
    display: "block",
  },
  itemContainer: {
    marginTop: "20px",
  },
  nameTitle: {
    fontSize: "22px",
    fontWeight: "600",
    color: "#f7d373",
  },
}))

const AboutUs = () => {
  const classes = useStyles()
  const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`
  return (
      <Layout page="about" >
        <SEO
          title="BEST HAVEN POOLVILLA"
          description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
          image={imgShare}
        />
        <Grid container className={classes.titleContainer} justifyContent="center">
          <Grid item>
            <span className={classes.title}>เกี่ยวกับเรา</span>
            <br />
            <div className={classes.underLineTitle}></div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          className={classes.itemContainer}
          spacing={2}
        >
          <Grid item className={classes.itemText} lg={6} md={6} xs={12} >
            <Carousel indicators={false}>
              {imageItems.map((item,index) => {
                return (
                  <div key={index}>
                    <img src={item} alt=""/>
                  </div>
                )
              })}
            </Carousel>
          </Grid>
          <Grid item className={classes.itemText} lg={6} md={6} xs={12}>
            <span className={classes.nameTitle}>BEST HAVEN POOL VILLA</span>
            <br />
            <br />
            <p>
              &nbsp;&nbsp;&nbsp;เราเป็นผู้ให้บริการ หรือ Agency
              ที่คอยจัดหาบ้านพักแบบส่วนตัวหลังใหญ่บริเวณโซนปากช่อง และเขาใหญ่
              โดยได้รับอนุญาตจากทางเจ้าของบ้านหรือรีสอร์ทนั้นๆ อย่างถูกต้อง
              และรับจองบ้านพักโดยตรง ตัวบ้านพักจะเป็นสไตล์ Pool Villa, Party
              House หรือ Private House แบบหลังใหญ่ๆ อยู่ได้หลายท่าน
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;เราได้รวบรวมบ้านพักสำหรับลูกค้าที่มีจุดประสงค์ต้องการหาบ้านพักดีๆสวยงาม และมีมาตรฐานแบบอยู่ได้หลายท่าน เหมาะสำหรับจัดงานปาร์ตี้, พักผ่อนแบบสบายๆกับเพื่อนๆ หรือครอบครัว, จัดงานสัมมนาหรือเลี้ยงรุ่นก็สามารถทำได้ โดยตัวบ้านที่จัดหาจะมีอุปกรณ์อำนวยความสะดวกมากมาย เช่น สระว่ายน้ำส่วนตัว, เตา BBQ, และคาราโอเกะ
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;โดยทางเราจะรับหน้าที่เป็นตัวกลาง คอยประสานงานและจัดหา “บ้านพักส่วนตัว” ให้ลูกค้าที่สนใจเข้าพักทุกท่าน ในดีลหรือราคาที่ดีที่สุด ในช่วงเวลานั้นๆ และที่สำคัญง่ายต่อการจอง สะดวก ไม่ต้องปวดหัว และเสียเวลากับการจองเองโดยตรง หรือ คอยหาไปทีละแห่งด้วยตัวเอง เพราะทางเรารวบรวมบ้านพักมากมายที่ตอบโจทย์ลูกค้า รวมถึงมอบความสะดวกสบายในการจองไว้ให้หมดแล้ว
            </p>
          </Grid>
          <Grid item className={classes.itemText} lg={12} xs={12}>
            <p>
              &nbsp;&nbsp;&nbsp;ลูกค้าสามารถสบายใจ
              และเชื่อใจเราได้ในการที่จะเลือกใช้บริการจองที่พักกับทางเราเพราะ
              เรามีประสบการณ์ในการทำงานด้านนี้รวมถึงปิดดีล
              และจองบ้านพักมาแล้วมากมายหลายครั้งโดยมีรีวิว
              และฟีดแบคดีๆจากลูกค้าเก่ามากมาย รวมถึงยังมีการลงพื้นที่จริง
              เก็บรูปภาพจากหน้างาน และเช็คสภาพบ้านที่เรามีให้เลือกอย่างต่อเนื่อง
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;เรามีเป้าหมายที่จะเป็นแหล่งรวมบ้านพัก
              และปากช่องประเภทพูลวิลล่า หรือบ้านพักตากอากาศส่วนตัวที่ดีที่สุด
              มีบ้านให้เลือกมากที่สุด
              และสะดวกสบายรวมถึงน่าเชื่อถือต่อลูกค้าทุกท่าน โดยพร้อมจะปรับปรุง
              และพัฒนากระบวนการทำงานให้ดีขึ้นเรื่อยๆอย่างไม่หยุดนิ่ง
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;หากสนใจสามารถติดตามเราได้โดยตรงผ่านทาง Official
              เพจ และเว็บไซต์ของ Best Haven POOL VILLA ของเรา
              โดยลูกค้าที่สนใจจองบ้านพักสามารถติดต่อเราได้โดยตรงผ่านช่องทางด้านล่างนี้
              โดยช่องทางที่สะดวกรวดเร็วที่สุดคือผ่านทาง Line Official ของเราครับ
            </p>
          </Grid>
        </Grid>
      </Layout>
  )
}

export default AboutUs
